import { Link, useMatches } from '@remix-run/react'
import {
	PanelLeft,
	Package2,
	Home,
	ShoppingCart,
	Package,
	Users2,
	LineChart,
} from 'lucide-react'

import { Fragment } from 'react/jsx-runtime'
import { z } from 'zod'
import {
	BreadcrumbList,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbSeparator,
	BreadcrumbPage,
} from './ui/breadcrumb'
import { Button } from './ui/button'

import { Sheet, SheetContent, SheetTrigger } from './ui/sheet'

export default function Header({ children }: { children: React.ReactNode }) {
	return (
		<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
			<Sheet>
				<SheetTrigger asChild>
					<Button size="icon" variant="outline" className="sm:hidden">
						<PanelLeft className="h-5 w-5" />
						<span className="sr-only">Toggle Menu</span>
					</Button>
				</SheetTrigger>
				<SheetContent side="left" className="sm:max-w-xs">
					<nav className="grid gap-6 text-lg font-medium">
						<Link
							to="#"
							className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
						>
							<Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
							<span className="sr-only">Acme Inc</span>
						</Link>
						<Link
							to="#"
							className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
						>
							<Home className="h-5 w-5" />
							Dashboard
						</Link>
						<Link
							to="#"
							className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
						>
							<ShoppingCart className="h-5 w-5" />
							Orders
						</Link>
						<Link
							to="#"
							className="flex items-center gap-4 px-2.5 text-foreground"
						>
							<Package className="h-5 w-5" />
							Products
						</Link>
						<Link
							to="#"
							className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
						>
							<Users2 className="h-5 w-5" />
							Customers
						</Link>
						<Link
							to="#"
							className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
						>
							<LineChart className="h-5 w-5" />
							Settings
						</Link>
					</nav>
				</SheetContent>
			</Sheet>
			<BreadcrumbHandle />
			{children}
		</header>
	)
}
export const BreadcrumbSchema = z.object({ breadcrumb: z.any() })
export type BreadcrumbHandler = z.infer<typeof BreadcrumbSchema>
const BreadcrumbHandleMatch = z.object({
	handle: BreadcrumbSchema,
})
const BreadcrumbHandle = () => {
	const matches = useMatches()
	const breadcrumbs = matches
		.map(m => {
			const result = BreadcrumbHandleMatch.safeParse(m)
			if (!result.success || !result.data.handle.breadcrumb) return null
			const isLast = matches[matches.length - 1] === m

			return (
				<Fragment key={m.id}>
					<BreadcrumbItem>
						{isLast ? (
							<BreadcrumbPage>{result.data.handle.breadcrumb}</BreadcrumbPage>
						) : (
							<BreadcrumbLink asChild>
								{result.data.handle.breadcrumb}
							</BreadcrumbLink>
						)}
					</BreadcrumbItem>
					{!isLast && <BreadcrumbSeparator />}
				</Fragment>
			)
		})
		.filter(Boolean)

	return (
		<Breadcrumb className="hidden md:flex">
			<BreadcrumbList>
				<BreadcrumbItem>
					<BreadcrumbLink asChild>
						<Logo />
					</BreadcrumbLink>
				</BreadcrumbItem>
				<BreadcrumbSeparator />
				{breadcrumbs}
			</BreadcrumbList>
		</Breadcrumb>
	)
}

function Logo() {
	return (
		<Link to="/" className="group grid leading-snug">
			<span className="font-light text-teal-300 transition group-hover:-translate-x-1">
				Tryggsurf
			</span>
			<span className="font-bold transition group-hover:translate-x-1">
				salesportal
			</span>
		</Link>
	)
}
