import { Link } from '@remix-run/react'
import {
	Home,
	ShoppingCart,
	Package,
	Users2,
	LineChart,
	Settings,
	LayoutDashboard,
} from 'lucide-react'
import { Tooltip, TooltipTrigger, TooltipContent } from './ui/tooltip'

const sidebarData = [
	{
		icon: Home,
		label: 'Hem',
		href: '/orders/recent',
	},
	{
		icon: ShoppingCart,
		label: 'Ordrar',
		href: '/orders',
	},
	{
		icon: Package,
		label: 'Produkter',
		href: '/products',
	},
	{
		icon: Users2,
		label: 'Kunder',
		href: '/customers',
	},
	{
		icon: LineChart,
		label: 'Rapporter',
		href: '/analytics',
	},
]
export const Sidebar = ({ children }: { children: React.ReactNode }) => {
	return (
		<aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
			<nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
				<Link
					to="/"
					className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
				>
					<LayoutDashboard className="h-4 w-4 transition-all group-hover:scale-110" />
					<span className="sr-only">Tryggsurf Salesportal</span>
				</Link>
				{sidebarData.map((item, index) => (
					<Tooltip key={index}>
						<TooltipTrigger asChild>
							<Link
								to={item.href}
								className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
							>
								<item.icon className="h-5 w-5" />
								<span className="sr-only">{item.label}</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">{item.label}</TooltipContent>
					</Tooltip>
				))}
			</nav>
			<nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
				{children}
				<Tooltip>
					<TooltipTrigger asChild>
						<Link
							to="/settings/profile"
							className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
						>
							<Settings className="h-5 w-5" />
							<span className="sr-only">Settings</span>
						</Link>
					</TooltipTrigger>
					<TooltipContent side="right">Settings</TooltipContent>
				</Tooltip>
			</nav>
		</aside>
	)
}
